




import { Component, Mixins, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import flashsale from "@/components/DramaThemeFlash/flashsale.vue";

@Component({
  components: { flashsale },
})
export default class ExternalAccessDramaThemeFlashPage extends Mixins(Mixin) {}
