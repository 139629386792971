



































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
// import moment from "moment";
import { wxShare } from "@/utils/wechat";
import {
  NavBar,
  Cell,
  CellGroup,
  Image,
  Field,
  Row,
  Tab,
  Tabs,
  Button,
  Icon,
} from "vant";
import wx from "weixin-js-sdk";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Field.name]: Field,
    [Row.name]: Row,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Button.name]: Button,
    [Icon.name]: Icon,
  },
})
export default class DramaThemePage extends Mixins(Mixin) {
  public isshowicon = true;
  //   public currentMonth = moment();
  public calendar = [];
  public tab: any;
  public button: any;
  public weekdays: any = {
    Sun: "周日",
    Mon: "周一",
    Tue: "周二",
    Wed: "周三",
    Thu: "周四",
    Fri: "周五",
    Sat: "周六",
  };
  public activeList: any = {
    targetone: 0,
    targettwo: 1,
    targetthree: 2,
    targetfour: 3,
    targetfive: 4,
    targetsix: 5,
    targetseven: 6,
    targeteight: 7,
    targetnine: 8,
    targetten: 9,
    targeteleven: 10,
    targettwelve: 11,
    targetthirteen: 12,
    targetfourteen: 13,
    targetfifteen: 14,
    targetsixteen: 15,
    targetseventeen: 16,
    targeteighteen: 17,
    targetnineteen: 18,
  };
  public isFixed = false;
  public issticky = false;
  public scrollLeft: any;
  get activeIndex(): number {
    return this.activeList[this.activeName];
  }
  public activeName = "";
  public shareInfo = {
    title: "【保利戏聚节】好戏不断，聚力开票",
    desc: "热门经典剧目，每天准点开抢！",
    imgUrl: "https://cdn.polyt.cn/png/20230626150809.png",
    link: `${location.origin}${location.pathname}#/project-management/drama-theme-flashSale?theaterId=40`,
  };

  //  判断日期是否是当天
  isToday(timestamp: any): boolean {
    return (
      new Date(timestamp).getTime() ===
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        0,
        0,
        0
      ).getTime()
    );
  }

  // 滚动到指定的tab
  scrollTopTab(activeName: string): void {
    this.issticky = true;

    this.$nextTick(() => {
      // 在scrollTo方法调用之前延迟一小段时间，以确保DOM元素已经完全渲染
      setTimeout(() => {
        (this.$refs.tabs as any).scrollTo(activeName);
        this.handleScroll();
        // document.getElementById(activeName)?.scrollIntoView(true);
      }, 100);
    });
  }

  mounted(): void {
    wxShare(
      this.shareInfo.title,
      this.shareInfo.desc,
      this.shareInfo.imgUrl,
      this.shareInfo.link
    );
    this.jsBridge("isShowShare", "1");
    window.startShare = this.share;
    // 获取当天的日期 判断是几号 tab定位到当天
    let datetime = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      0,
      0,
      0
    ).getTime();
    let date = this.timestampFormat(datetime, "YYYY-MM-DD");
    switch (date) {
      case "2023-06-28":
        this.$set(this, "activeName", "targetone");
        this.scrollTopTab("targetone");
      case "2023-06-29":
        this.$set(this, "activeName", "targettwo");
        this.scrollTopTab("targettwo");
        break;
      case "2023-06-30":
        this.$set(this, "activeName", "targetthree");
        this.scrollTopTab("targetthree");
        break;
      case "2023-07-01":
        // this.$set(this, "activeName", "targetfour");
        // this.scrollTopTab("targetfour");
        this.$set(this, "activeName", "targetfive");
        this.scrollTopTab("targetfive");
        break;
      case "2023-07-02":
        this.$set(this, "activeName", "targetfive");
        this.scrollTopTab("targetfive");
        break;
      case "2023-07-03":
        this.$set(this, "activeName", "targetsix");
        this.scrollTopTab("targetsix");
        break;
      case "2023-07-04":
        this.$set(this, "activeName", "targetseven");
        this.scrollTopTab("targetseven");
        break;
      case "2023-07-05":
        this.$set(this, "activeName", "targeteight");
        this.scrollTopTab("targeteight");
        break;
      case "2023-07-06":
        this.$set(this, "activeName", "targetnine");
        this.scrollTopTab("targetnine");
        break;
      case "2023-07-07":
        this.$set(this, "activeName", "targetten");
        this.scrollTopTab("targetten");
        break;
      case "2023-07-08":
        this.$set(this, "activeName", "targeteleven");
        this.scrollTopTab("targeteleven");
        break;
      case "2023-07-09":
        this.$set(this, "activeName", "targettwelve");
        this.scrollTopTab("targettwelve");
        break;
      case "2023-07-10":
        this.$set(this, "activeName", "targetthirteen");
        this.scrollTopTab("targetthirteen");
        break;
      case "2023-07-11":
        this.$set(this, "activeName", "targetfourteen");
        this.scrollTopTab("targetfourteen");
        break;
      case "2023-07-12":
        this.$set(this, "activeName", "targetfifteen");
        this.scrollTopTab("targetfifteen");
        break;
      case "2023-07-13":
        this.$set(this, "activeName", "targetsixteen");
        this.scrollTopTab("targetsixteen");
        break;
      case "2023-07-14":
        this.$set(this, "activeName", "targetseventeen");
        this.scrollTopTab("targetseventeen");
        break;
      case "2023-07-15":
        this.$set(this, "activeName", "targeteighteen");
        this.scrollTopTab("targeteighteen");
        break;
      case "2023-07-16":
        this.$set(this, "activeName", "targetnineteen");
        this.scrollTopTab("targetnineteen");
        break;
    }
    window.requestAnimationFrame(this.navScroll);
    window.requestAnimationFrame(this.wrapperScroll);
  }
  //   app分享
  share(): void {
    this.jsBridge(
      "share",
      `title%&@/${this.shareInfo.title}%&@,desc%&@/${this.shareInfo.desc}%&@,picUrl%&@/${this.shareInfo.imgUrl}%&@,url%&@/${this.shareInfo.link}`
    );
  }
  // 判断图片是否展示
  isShow(saletime: any): boolean {
    // 将时间转成时间戳 判断当前时间是否大于等于saletime 大于展示正在抢票的图片 反之展示预售图片
    let timestamp = new Date(saletime).getTime();
    return new Date().getTime() >= timestamp;
  }
  // 跳转详情页
  jumpDetail(productId: string, tourId: string, theaterId: string): void {
    if (this.ChannelModule.channel === "plat_h5") {
      this.$router.push(`/projectdetail/${productId}/${tourId}`);
    } else if (this.ChannelModule.channel === "plat_wechat_miniapp") {
      wx.miniProgram.navigateTo({
        url: `/pagesIndex/performDetail/performDetail?id=${productId}`,
      });
    } else {
      this.jsBridge(
        "goProDetail",
        `productId/${productId},theaterId/${theaterId},taskId/null`
      );
    }
  }

  scroll(callback: { scrollTop: number; isFixed: boolean }): void {
    this.isFixed = callback.isFixed;
    this.handleScroll();
  }
  handleScroll() {
    const scrollTop = (document.querySelector(".wrapper") as any).scrollTop;
    const offsetTop = (document.querySelector(".van-tabs__nav") as any)
      .offsetLeft;
    let elements = document.getElementsByClassName("van-tabs__nav");
    if (scrollTop < 240 || !this.isFixed) {
      (elements[0] as any).style.height = "2rem";
    } else {
      (elements[0] as any).style.height = "2.2rem";
      (elements[0] as any).style.paddingTop = "0.3rem";
    }
    this.navScroll();
  }
  wrapperScroll() {
    let wrapperArea = document.querySelector(".wrapper");
    wrapperArea?.addEventListener("scroll", () => {
      this.issticky = true;
    });
  }
  // 标签栏的滚动 判断标签栏最后一个标签是否进入了可视区域 进入可视区域就隐藏渐变的按钮
  navScroll() {
    (document.querySelectorAll(".van-tab")[17] as any).style.background =
      "transparent";
    (document.querySelectorAll(".van-tab")[17] as any).style.marginRight = "0";
    (document.querySelectorAll(".van-tab")[17] as any).style.width = "0.5rem";
    let scrollArea = document.querySelector(".van-tabs__nav");
    scrollArea!.addEventListener("scroll", () => {
      this.issticky = true;
      const lastTabRight = document
        .querySelectorAll(".van-tab")[16]
        .getBoundingClientRect().right;
      const tabsWrapperRight = document
        .querySelector(".van-tabs__nav")!
        .getBoundingClientRect().right;
      if (parseInt(String(lastTabRight)) > parseInt(String(tabsWrapperRight))) {
        // 最后一个标签的位置超出了可视区域
        this.isshowicon = true;
        // console.log("最后一个标签的位置超出了可视区域");
      } else {
        // 最后一个标签的位置在可视区域内
        // console.log("最后一个标签的位置在可视区域内");
        (document.querySelectorAll(".van-tab")[16] as any).style.marginRight =
          "0.5em";
        this.isshowicon = false;
      }
    });
  }

  tabchange(info: any) {
    this.issticky = true;
    return true;
  }

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    let scrollArea = document.querySelector(".van-tabs__nav");
    scrollArea?.removeEventListener("scroll", this.navScroll);
    let wrapperArea = document.querySelector(".wrapper");
    wrapperArea?.removeEventListener("scroll", this.wrapperScroll);
  }
}
